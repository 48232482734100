import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexView from '../views/IndexView.vue'

Vue.use(VueRouter)

const routes =[

  {
    path: '/',
    name: 'home',
    component: IndexView
  },
  {
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/IndexView.vue')
  },
  {
    path: '/indexm',
    name: 'indexm',
    component: () => import(/* webpackChunkName: "about" */ '../views/IndexmView.vue')
  },
  {
    path: '/freetry',
    name: 'freetry',
    component: () => import(/* webpackChunkName: "about" */ '../views/FreetryView.vue')
  },
  {
    path: '/online',
    name: 'online',
    component: () => import(/* webpackChunkName: "about" */ '../views/OnlineView.vue')
  },
  {
    path: '/bot',
    name: 'bot',
    component: () => import(/* webpackChunkName: "about" */ '../views/BotView.vue')
  },
  {
    path: '/call',
    name: 'call',
    component: () => import(/* webpackChunkName: "about" */ '../views/CallcenterView.vue')
  },
  {
    path: '/smart',
    name: 'smart',
    component: () => import(/* webpackChunkName: "about" */ '../views/SmartView.vue')
  },
  {
    path: '/mebot',
    name: 'mebot',
    component: () => import(/* webpackChunkName: "about" */ '../views/MebotView.vue')
  },
  {
    path: '/intell',
    name: 'intell',
    component: () => import(/* webpackChunkName: "about" */ '../views/IntellView.vue')
  },
  {
    path: '/video',
    name: 'video',
    component: () => import(/* webpackChunkName: "about" */ '../views/VidoView.vue')
  },
  {
    path: '/custer',
    name: 'custer',
    component: () => import(/* webpackChunkName: "about" */ '../views/CusterView.vue')
  },
  {
    path: '/data',
    name: 'data',
    component: () => import(/* webpackChunkName: "about" */ '../views/DataView.vue')
  },
  {
    path: '/outcall',
    name: 'outcall',
    component: () => import(/* webpackChunkName: "about" */ '../views/OutcallView.vue')
  },
  {
    path: '/platform',
    name: 'platform',
    component: () => import(/* webpackChunkName: "about" */ '../views/PlatformView.vue')
  },
  {
    path: '/scheme',
    name: 'scheme',
    component: () => import(/* webpackChunkName: "about" */ '../views/SchemeView.vue')
  },
  {
    path: '/tech',
    name: 'tech',
    component: () => import(/* webpackChunkName: "about" */ '../views/TechView.vue')
  },

  {
    path: '/down',
    name: 'down',
    component: () => import(/* webpackChunkName: "about" */ '../views/DownView.vue')
  },
  {
    path: '/gover',
    name: 'gover',
    component: () => import(/* webpackChunkName: "about" */ '../views/GoverView.vue')
  },
  {
    path: '/fresh',
    name: 'fresh',
    component: () => import(/* webpackChunkName: "about" */ '../views/FreshView.vue')
  },
  {
    path: '/serve',
    name: 'serve',
    component: () => import(/* webpackChunkName: "about" */ '../views/ServeView.vue')
  },
  {
    path: '/buy',
    name: 'buy',
    component: () => import(/* webpackChunkName: "about" */ '../views/BuyView.vue')
  },
  {
    path: '/reward',
    name: 'reward',
    component: () => import(/* webpackChunkName: "about" */ '../views/RewardView.vue')
  },
  {
    path: '/sea',
    name: 'sea',
    component: () => import(/* webpackChunkName: "about" */ '../views/SeaView.vue')
  },
  {
    path: '/fasion',
    name: 'fasion',
    component: () => import(/* webpackChunkName: "about" */ '../views/FasionView.vue')
  },
  {
    path: '/medic',
    name: 'medic',
    component: () => import(/* webpackChunkName: "about" */ '../views/MedicView.vue')
  },
  {
    path: '/mech',
    name: 'mech',
    component: () => import(/* webpackChunkName: "about" */ '../views/MechView.vue')
  },
  {
    path: '/car',
    name: 'car',
    component: () => import(/* webpackChunkName: "about" */ '../views/CarView.vue')
  },
  {
    path: '/game',
    name: 'game',
    component: () => import(/* webpackChunkName: "about" */ '../views/GameView.vue')
  },
  {
    path: '/book',
    name: 'book',
    component: () => import(/* webpackChunkName: "about" */ '../views/BookView.vue')
  },
  {
    path: '/bookt',
    name: 'bookt',
    component: () => import(/* webpackChunkName: "about" */ '../views/BooktView.vue')
  },
  {
    path: '/booko',
    name: 'booko',
    component: () => import(/* webpackChunkName: "about" */ '../views/BookoView.vue')
  },
  {
    path: '/intro',
    name: 'intro',
    component: () => import(/* webpackChunkName: "about" */ '../views/IntroView.vue')
  },
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

