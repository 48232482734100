<template>
    <div class="bottomm" v-show="this.top">
        <div class="head">
            <div class="h-a">
                <div class="title">7天免费试用</div>
                <div class="h-body"><span><input type="text" placeholder="请填写您的手机号"></span>
                    <div class="tree">免费试用</div>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="b-head"></div>
            <div class="b-body"></div>
            <div class="b-foot"></div>
            <div class="b-down"></div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import SparkMD5 from "spark-md5";
export default {
    data() {
        return {
            top: false
        }
    },
    onLoad() {

    },

    methods: {


    },
    mounted() {
        if (window.outerWidth < 1200) {
            this.top = true
        } else {
            this.top = false
        }
    },
}



</script>
<style lang="scss">
.bottomm {
    width: 100%;
    min-width: 375px;
    .body{
        background-color: #30343e;
        padding: 0 10px;
    }
    .head {
        min-height: 1.6rem;
        background-color: #545b69;
        background-image: url(../../assets/img/m七天免费.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 0 20px;

        height: 160px;

        .h-a {
            display: block;
            padding-top: 30px;


            .title {
                font-size: 24px;
                line-height: 1.5;
                color: #f5f6f7;
                letter-spacing: 0.015rem;
                text-align: center;
            }
            .h-body {
                padding-top: 20px;
                display: flex;
            }
            .tree {
                box-sizing: border-box;
                width: 100px;
                height: 40px;
                padding: 0;
                font-size: 14px;
                line-height: 40px;
                white-space: nowrap;
                text-align: center;
                border-radius: 0 6px 6px 0;
                display: inline-block;
                vertical-align: baseline;
                zoom: 1;
                padding: 0.1rem 0.3rem;
                vertical-align: middle;
                background-color: #337eff;
                border: 1px solid #337eff;
                border-radius: 4px;
                outline: 0;
                font-size: 14px;

                text-align: center;
                color: #fff;

                text-decoration: none;
                cursor: pointer;


            }

            span {
                display: block;
                overflow: hidden;
                width: 100%;

                input {
                    box-sizing: border-box;
                    height: 40px;
                    font-size: 16px;

                    line-height: 40px;
                    color: #70727d;
                    background-color: #fff;
                    border: 0;
                    border-radius: 4px 0 0 4px;
                    vertical-align: middle;
                    width: 100%;
                    padding: 5px 10px;

                }

            }
        }

    }
}
</style>
