
<template>
	<div class="all-bottom" ref="deleteall" v-show="this.$store.state.show">
		<div class="a-b-all"><span>现在注册，立即领取免费试用机会！</span>
			<div class="a-b-left">
				<div class="b-try" @click="freetr()">免费试用</div>
			
			</div>
		</div>
	</div>
</template>
<script>
import axios from 'axios';
import SparkMD5 from "spark-md5";
export default {
	data() {

	},
	onLoad() {

	},

	methods: {
		freetr() {

			this.$router.push({ name: 'freetry' })
			clearInterval(this.set)
			this.set = null
			
		},
		delet() {

			this.$refs.deleteall.style = "display:none;"
		},


	},
}



</script>
<style lang="scss">
.all-bottom{
	min-width: 1200px;
	.producs{
		width: 20%;
		padding: 20px 30px;
	   margin-bottom: 10px;
	}
	
	.v-h-f-mid li{
	
		display: flex;
	}
	.g-new-sitemap-footer {
		padding: 16px;
		text-align: center;
		font-size: 12px;
		line-height: 1;
		color: rgba(26, 34, 51, 0.5);
		box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
	
	
	
	}
	.product{
		width: 1200px;
		display: flex;
		flex-wrap: wrap;
		cursor: pointer;
	}
	.product div:hover{
		color: #4382ff;
	}
	.prd dt dd {
		display: block;
		width: 220px;
	}
	
	.l-p-dd div {
		font-size: 12px;
	}
	
	.auth {
		width: 340px !important;
	}
	.choose{
		width: 1200px;
		margin: 0 auto;
		margin-top: 40px;
		margin-bottom: 80px;
	}
	.choose img{
	width: 1200px;
	}
	.choose div{
		font-size: 32px;
		font-weight: 600;
		width: 1200px;
		text-align: center;
		margin-bottom: 20px;
	}
	.lists dt {
		margin-bottom: 24px;
		line-height: 24px;
		font-weight: 600;
		font-size: 18px;
		cursor: pointer;
	}
	
	.l-a-dd {
		display: flex;
		height: 50px;
	
	}
	
	.add {
		width: 96px !important;
	}
	
	.itemcc {
		height: 40px;
		margin-top: 3px;
	}
	
	.ddimg img {
		width: 48px;
		height: 49px;
	}
	
	.ddimg {
		margin-right: 12px;
	}
	
	.list dd {
		line-height: 18px;
		font-size: 14px;
		font-weight: 400;
		color: #1a2233;
		margin-bottom: 15px;
		text-align: left;
		margin-left: 0;
		cursor: pointer;
	}
	
	.list {
		width: 220px;
	}
	
	.lists {
		display: flex;
	}
	
	.g-new-sitemap-content {
		margin: 0 auto;
		padding: 60px 0 40px;
		width: 1200px;
	}
	
	.bot-bottom {
		width: 100%;
		background-color: #cce4ff;
		height: 638px;
		margin-top: -30px;
	}
	
	.ph-free {
		position: absolute;
		top: 6px;
		right: 6px;
		padding: 12px 24px 12px 24px;
		font-size: 16px;
		border-radius: 24px;
		display: inline-block;
		cursor: pointer;
		line-height: 150%;
		color: #fff;
		font-weight: 400;
		background-color: #1861f2;
		overflow: hidden;
	
	
	
	}
	
	.phonenumber {
		position: relative;
	}
	
	.phonenumber input {
		display: block;
		padding: 16px 24px;
		box-sizing: border-box;
		width: 480px;
		line-height: 24px;
		font-size: 16px;
		border: none;
		border-radius: 28px;
		border: 2px solid transparent;
		position: relative;
	}
	
	.blue {
		color: #39f;
	}
	
	.g-n-title {
		margin-bottom: 32px;
		line-height: 150%;
		font-size: 32px;
		font-weight: 600;
		color: white;
	}
	
	.g-new-trial {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		width: 1200px;
		height: 240px;
		background-color: #1a2233;
		border-radius: 16px;
	}
	
	.m-m-b-r-down {
		padding: 16px 24px;
		margin-top: 8px;
		border-radius: 8px;
		cursor: pointer;
	
	}
	
	.m-m-b-r-down div {
		text-align: left;
	}
	
	.m-m-t-b-title {
		font-size: 20px;
		font-weight: 600;
		height: 30px;
		line-height: 30px;
	}
	
	.m-m-t-b-foot div {
		margin-right: 4px;
		padding: 8px 8px;
		box-sizing: border-box;
		border-radius: 2px;
		font-size: 12px;
		color: white;
	}
	
	.m-m-t-b-foot {
		display: none;
		margin-top: 16px
	}
	
	.m-m-t-b-body {
		margin-top: 8px;
		font-size: 14px;
		color: #676b73;
	
	
	
	}
	
	.m-m-t-b-foot {
		display: flex;
	
	}
	
	.m-m-t-b-right {
		width: 540px;
		text-align: center;
	}
	.v-h-f-mid li:hover{
	 color: #538cff;
	}
	.m-m-t-body {
		width: 1200px;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		margin-bottom: 60px;
	
	}
	
	.m-m-t-b-left {
		width: 620px;
		overflow: hidden;
		height: 504px;
	
	}
	
	.m-m-t-b-left img {
		width: 620px;
		height: 504px;
		display: block;
	}
	
	.m-m-t-h2 {
		margin-bottom: 60px;
		line-height: 48px;
		font-weight: 600;
		font-size: 32px;
		text-align: center;
	}
	
	.m-m-t-h2 span {
		color: #1861f2;
	}
	
	.m-homePage-touch {
		margin-top: 120px;
		text-align: center;
		margin-bottom: 60px;
	}
	
	.m-h-d-body {
		height: 105px;
		line-height: 150%;
		text-align: left;
		font-size: 14px;
		font-weight: 400;
		color: #676b73;
		width: 242px;
		margin: 0 auto;
		margin-top: 16px;
	}
	
	.m-h-d-foot div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 12px 12px 0;
		padding: 3px 12px;
		width: 115px;
		line-height: 20px;
		font-size: 14px;
		color: #1a2233;
		border: 1px solid transparent;
		border-radius: 14px;
		box-sizing: border-box;
		text-align: left;
		background-color: #dce6fa;
		width: 140px;
	
	
	}
	
	.m-h-d-foots div:hover {
		background-color: #bcd2ff;
		cursor: pointer;
	}
	
	.m-h-d-foot div:hover {
		background-color: #bcd2ff;
		cursor: pointer;
	}
	
	.m-h-d-foots {
		width: 242px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 10px;
	}
	
	.m-h-d-foot {
		width: 242px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 10px;
	}
	
	.m-h-d-foots div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 0 12px 0;
		padding: 3px 12px;
		width: 115px;
		line-height: 20px;
		font-size: 14px;
		color: #1a2233;
		border: 1px solid transparent;
		border-radius: 14px;
		box-sizing: border-box;
		text-align: left;
		background-color: #dce6fa;
		width: 115px;
	}
	
	.m-h-down {
		height: 240px;
		width: 282px;
		margin-top: -12px;
		padding-top: 24px;
		background-color: white;
		z-index: 2;
		border-radius: 12px;
	}
	
	
	
	
	.m-h-d-head {
		display: flex;
		width: 242px;
		margin: 0 auto;
		height: 34px;
	
	}
	
	.m-h-d-head div {
		height: 34px;
		line-height: 34px;
		font-size: 20px;
		font-weight: 600;
	}
	
	.m-h-down img {
		width: 34px;
		height: 34px;
		margin-right: 12px;
	}
	
	.m-h-img img {
		width: 100%;
		z-index: -1;
	}
	
	.m-h-body {
		display: flex;
		justify-content: space-between;
		margin-bottom: 60px;
	}
	
	.m-h-body>div {
		height: 435px;
		width: 282px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2), 0px 8px 15px -8px rgba(25, 34, 51, 0.1);
		border-radius: 12px;
	}
	
	.m-h-img {
		z-index: -1;
	}
	
	.m-free {
		padding: 12px 24px 12px 24px;
		font-size: 16px;
		border-radius: 24px;
		display: inline-block;
		line-height: 150%;
		color: #fff;
		font-weight: 400;
		background-color: #1861f2;
		overflow: hidden;
		cursor: pointer;
	}
	
	.m-free:hover {
		background-color: #7ba7ff;
	}
	
	.m-h-mid {
		line-height: 48px;
		font-weight: 600;
		font-size: 32px;
		text-align: center
	}
	
	body {
		margin: 0;
		padding: 0;
	}
	
	.all {
		min-width: 1200px;
		width: 100%;
	}
	

	
	

	

	
	.swiper-pagination-bullet {
		width: 40px !important;
		height: 4px !important;
		border-radius: 10px !important;
		background-color: #8e9091 !important;
	
	}
	
	.swiper-pagination {
		text-align: left !important;
		margin-left: 10%;
		top: 460px;
		width: 70%;
	}
	
	.swiper-pagination-bullets {}
	
	.v-h-all {
		width: 94%;
		height: 28px;
		display: flex;
		margin: 0 auto;
		justify-content: space-between;
	
	}
	
	.v-h-left {
		display: flex;
		width: 40%;
	
	}
	
	.h-l-a {
		font-size: 12px;
		text-align: center;
		text-decoration: none;
		color: rgba(26, 34, 51, 0.5);
		height: 16px;
		margin-top: 6px;
		display: block;
		box-sizing: border-box;
	}
	
	.v-h-left div {
		width: 25%;
		text-align: center;
		display: block;
		box-sizing: border-box;
	}
	
	.v-h-foot {
		width: 94%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}
	
	.v-h-f-left {
	
		height: 30px;
		margin-top: 15px;
	
	}
	
	.v-h-f-left img {
	
		height: 30px;
	
	}
	
	.all-bottom {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		opacity: 0.9;
		background: #373d40;
		height: 45px;
		line-height: 45px;
		padding: 12px 0;
		z-index: 20;
	
	}
	
	
	.a-b-left {
		display: flex;
		align-items: flex-start
	}
	
	.b-try {
		display: block;
		background: #337eff;
		border-radius: 5px;
		width: 144px;
		border: 0;
		font-size: 16px;
		color: #fff;
		text-align: center;
	
		text-decoration: none;
	
	}
	
	.b-x {
		color: #fff;
		vertical-align: top;
		margin-left: 15px;
		position: relative;
		top: -15px;
		text-decoration: none;
		cursor: pointer;
	}
	
	
	
	
	
	
	
	
	
	
	
	.swiper {
		width: 100%;
		height: 100%;
	}
	
	.swiper-pagination {
		width: 80% !important;
	}
	
	.swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	
	
	
	.c-img {
		width: 50%;
	
	}
	
	.cust-img {
		width: 100%;
		height: auto !important;
		margin-top: 100px;
	}
	
	.cust-swpier {
		width: 210px;
		height: 32px;
		overflow: hidden;
	}
	
	.cust-swpier div {}
	
	.cust-body {
		font-size: 24px;
		display: flex;
		flex-wrap: wrap;
		margin-top: 20px;
	}
	
	.cust-title {
		font-weight: 600;
		font-size: 48px;
		text-align: left;
	}
	
	
	.cust-bottom {
		display: flex;
		font-size: 20px;
		margin-top: 35px;
	}
	
	.c-auto {
		display: flex;
		line-height: 54px;
		margin-left: 30px;
		cursor: pointer;
	}
	
	.c-auto:hover {
		color: #568dfc;
	}
	
	.c-auto .iconfont {
		font-size: 28px;
	}
	
	.c-free {
		display: inline-block;
		position: relative;
		line-height: 150%;
		color: #fff;
		font-weight: 400;
		background-color: #1861f2;
		overflow: hidden;
		padding: 12px 52px 12px 52px;
		font-size: 20px;
		border-radius: 27px;
		cursor: pointer;
	}
	
	.c-free:hover {
		background-color: #568dfc;
	}
	
	.operation {
		width: 100%;
	}
	
	
	
	.o-b-b {
		width: 25%;
		padding: 0 24px;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		cursor: pointer
	}
	
	.o-b-b img {
		width: 78px;
		height: 104px;
		border-radius: 4px;
	}
	
	.o-title {
		display: flex;
		align-items: center;
		margin-bottom: 8px;
		line-height: 24px;
		font-weight: 600;
		font-size: 15px
	}
	
	.o-down {
		display: flex;
	}
	
	.o-d-left {
		line-height: 19.5px;
		font-size: 13px;
		color: #676b73
	}
	
	
	
	
	
	.o-d-right:hover {
		background-color: #ffce96;
	}
	
	
	
	.toger {
		margin: 20px 0 40px;
		width: 1200px
	}
	.fix{	
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
	}
	
	.daohang {
		max-width: 750px;
		min-width: 350px;
		height: 55px;
		margin: 0 auto;
		background-color: white;
		display: flex;
		justify-content: space-between;
	}
	
	.daohang>div img {
		display: block;
		width: 24px;
		height: 25px;
		margin: 0 auto;
	}
	
	.daohang>div {
		width: 20%;
		float: left;
		margin-top: 5px;
	
	}
	
	.daohang>div span {
		display: block;
		text-align: center;
		color: black;
	
		font-size: 15px;
	}
	
	.daohang>div:hover {
		cursor: pointer;
	}
	.b-try{
		cursor: pointer;
	}
	.a {
		text-decoration: none;
		color: black;
	}
	
	.daohang {
		border-top: 1px solid rgb(230, 230, 230);
	}
}


</style>
