<template>
  <div class="rightmall" ref="right">
    <div class="rightm" v-show="this.right">
      <div class="one">
        <div class="r-head"><img src="../../assets/img/网易七鱼授权合作伙伴 logo - 反白(网络推广可用).png" alt=""></div>
        <div class="close iconfont icon-close" @click="close()"></div>
      </div>
      <div class="one">
        <div class="o-left">产品</div>
        <div class="close iconfont icon-arrow-down-bold"></div>
      </div>
      <div class="one">
        <div  class="o-left">解决方案</div>
        <div class="close iconfont icon-arrow-down-bold"></div>
      </div>
      <div class="one">
        <div  class="o-left">购买</div>
        <div class="close iconfont icon-arrow-down-bold"></div>
      </div>
      <div class="one">
        <div  class="o-left">推荐奖励</div>
        <div></div>
      </div>
      <div class="one">
        <div  class="o-left">渠道合作</div>
        <div></div>
      </div>
    </div>
    <div class="head" v-show="this.show">
      <div class="headbody">
        <div class="h-l"><img src="../../assets/img/网易七鱼授权合作伙伴 logo - 反白(网络推广可用).png" alt=""></div>
        <div class="h-r">
          <div class="freetry">免费试用</div>
          <div class="kind iconfont icon-qita" @click="shown()">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import YSF from '@neysf/qiyu-web-sdk';
export default {
  data() {
    return {
      show: false,
      right: false,
      activeNames: [''],
    }
  },
  onLoad() {

  },
  mounted() {

  },
  methods: {

    shown() {
      this.right = true
    },
    close() {
      this.right = false
    }
  },
  mounted() {
    console.log(this.$refs.right.offsetWidth);
    if (this.$refs.right.offsetWidth < 1200) {
      this.show = true
    } else {
      this.$router.push({ name: 'index' })
    }
  },
  beforeDestroy() {

  }
}



</script>
<style lang="scss">
.rightmall {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;

  .r-head {
    img {
      width: 60px;
    }
  }

  .van-collapse-item {
    background-color: #373b48;
  }

  .van-cell {
    background-color: #373b48;
    color: white;
  }

  .van-collapse-item__content {
    background-color: #373b48;
    color: white;
  }

  .close {
    cursor: pointer;
    color: white;
  }

  .one {
    height: 50px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #515560;

  }
  .o-left{
    color: white;
  }
  .head {
    min-width: 375px;
    width: 100%;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    left: 0;
    right: 0;
    background-color: #373b48;
    padding: 0 10px 0 20px;
    height: 50px;

    .kind {
      color: white;
      cursor: pointer;
      font-size: 24px;
      margin-top: 11px;
    }

    .headbody {

      width: 98%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .freetry {
        height: 14px;
        width: 56px;
        background-color: #337eff;
        color: white;
        padding: 8px 20px;
        text-align: center;
        font-size: 12px;
        line-height: 14px;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 10px;
      }

      .h-l {
        width: 100px;
        padding-top: 15px;

        img {
          width: 80px;
          cursor: pointer;
        }
      }

      .h-r {

        display: flex;
        justify-content: space-between;
        width: 140px;
      }

    }
  }

  .rightm {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 100;
    width: 280px;
    background-color: #373b48;
  }
}
</style>