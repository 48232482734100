<template>
  <div id="app">
    <meta name="keywords" content="网易七鱼,七鱼,saas,智能客服,网易七鱼授权合作伙伴">
    <meta name="description"
      content='网易七鱼-服务与营销一体化解决方案，融合了智能机器人、在线客服、呼叫中心、工单系统、精准营销与智能大屏的等一系列人工智能客服的一体化智能平台，打造专业优质的客服服务体验。'>
    <meta name="viewport" content="width=device-width,initial-scale=1,user-scalable=no">
    <meta name="keywords" content="网易七鱼,七鱼,saas,智能客服,网易七鱼授权合作伙伴,网易,在线机器人,机器人客服">
    <meta name="robots" content="网易七鱼,七鱼,saas,智能客服,网易七鱼授权合作伙伴,网易,在线机器人,机器人客服">
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">
    <Head-view></Head-view>
    <router-view></router-view>
    <Bottom-view></Bottom-view>
    <Right-view></Right-view>
    <BtomView></BtomView>
    <RightmView></RightmView>
    <BottommView></BottommView>
  </div>
</template>
<script>
import BottomView from "./components/base/AllbottomView.vue";
import RightView from "./components/base/RightsearchView.vue";
import BtomView from "./components/base/BottomView.vue";
import HeadView from "./components/base/HeadView.vue";
import RightmView from "./components/base/RightmView.vue";
import BottommView from "./components/base/BottommView.vue";
export default {
  data: function () {
    return {

    }
  },

  components: {
    BottomView,
    RightView,
    BtomView,
    HeadView,
    RightmView,
    BottommView
  }
}
</script>


